<div class="d-flex justify-content-evenly">
    <div class="btn-group" role="group">
        <button routerLink="/prices/belt" routerLinkActive="active" type="button" class="btn btn-outline-ore">
            <fa-icon [icon]="oreIcon"></fa-icon>
            Belt ores
        </button>
        <button routerLink="/prices/moon" routerLinkActive="active" type="button" class="btn btn-outline-ore">
            <fa-icon [icon]="oreIcon"></fa-icon>
            Moon ores
        </button>
        <button routerLink="/prices/triglavian" routerLinkActive="active" type="button" class="btn btn-outline-ore">
            <fa-icon [icon]="oreIcon"></fa-icon>
            Triglavian ores
        </button>
    </div>

    <div class="btn-group" role="group">
        <button routerLink="/prices/ice" routerLinkActive="active" type="button" class="btn btn-outline-ice">
            <fa-icon [icon]="iceIcon"></fa-icon>
            Ice
        </button>
    </div>

    <div class="btn-group" role="group">
        <button routerLink="/prices/fullerenes" routerLinkActive="active" type="button" class="btn btn-outline-gas">
            <fa-icon [icon]="gasIcon"></fa-icon>
            Fullerenes
        </button>
        <button routerLink="/prices/booster-gas-clouds" routerLinkActive="active" type="button" class="btn btn-outline-gas">
            <fa-icon [icon]="gasIcon"></fa-icon>
            Booster Gas Clouds
        </button>
    </div>
</div>

<hr>

<ng-container *ngIf="visibleData; else ore_loading_message">
    <p>Prices are based on buying/selling 5.000m³ of product in Jita.</p>
    <app-sor-table [data]="visibleData" [columns]="tableSettings"></app-sor-table>
</ng-container>

<ng-template #ore_loading_message>
    <app-loading-message>Loading...</app-loading-message>
</ng-template>
