<div *ngIf="industryJobs && !industryJobs.length">
    <h3>You have no active industry jobs.</h3>
</div>

<!-- Add multiple character to merge industry list? -->

<div class="industry-job-container" *ngFor="let job of industryJobs">
    <h4 class="icon-container">
        <fa-icon [ngbTooltip]="getIndustryActivityName(job.activity_id)" placement="right"
                 [icon]="getIconForIndustryActivity(job.activity_id)"></fa-icon>
    </h4>
    <img src="https://images.evetech.net/types/{{ job.blueprint_type_id }}/{{job.inputImageType}}?size=64" alt="bp">
    <h4 class="icon-container">
        <fa-icon [icon]="arrowRight"></fa-icon>
    </h4>
    <img src="https://images.evetech.net/types/{{ job.product_type_id }}/{{job.outputImageType}}?size=64" alt="bp">

    <div class="icon-container">
        <h4 *ngIf="![IndustryActivity.research_material_efficiency, IndustryActivity.research_time_efficiency].includes(job.activity_id)">
            <fa-icon [icon]="jobRunsIcon"></fa-icon>
            {{ job.runs }}
            <ng-container *ngIf="job.activity_id === IndustryActivity.invention">
                (
                <fa-icon [icon]="inventionChanceIcon" size="sm"></fa-icon>
                {{ job.probability * 100 | number:'1.0-0' }}%
                )
            </ng-container>
        </h4>

        <h4 *ngIf="job.activity_id === IndustryActivity.research_material_efficiency">
            {{ blueprints[job.blueprint_id] && blueprints[job.blueprint_id]!.material_efficiency }}%
            <fa-icon [icon]="arrowRight"></fa-icon>
            {{ blueprints[job.blueprint_id] && blueprints[job.blueprint_id]!.material_efficiency + job.runs }}%
        </h4>

        <h4 *ngIf="job.activity_id === IndustryActivity.research_time_efficiency">
            {{ blueprints[job.blueprint_id] && blueprints[job.blueprint_id]!.time_efficiency }}%
            <fa-icon [icon]="arrowRight"></fa-icon>
            {{ blueprints[job.blueprint_id] && blueprints[job.blueprint_id]!.time_efficiency + job.runs * 2 }}%
        </h4>
    </div>

    <p class="percentage-container">
        <span>
<!--                TODO: figure out if successful_runs can work with invention. -->
            <!--                <ng-container *ngIf="job.activity_id === IndustryActivity.invention">-->
            <!--                    {{ job.successful_runs || 0 }} / {{ job.runs }} successful - -->
            <!--                </ng-container>-->
            {{ job.percentageDone | number:'1.0-0' }}%
            <fa-icon *ngIf="job.timeCountdown.value > 0" [icon]="jobInProgressIcon" [spin]="!(!!job.pause_date)"></fa-icon>
            <fa-icon *ngIf="job.timeCountdown.value <= 0" [icon]="jobFinishedIcon"></fa-icon>
        </span>
        <span> {{ job.timeCountdown.value > 0 ? job.timeCountdown : 'Ready for delivery' }}</span>
        <span>
            <fa-icon [icon]="locationIcon"></fa-icon>
            {{ job.locationName }}<ng-container *ngIf="job.locationType"> ( {{job.locationType}} )</ng-container>
        </span>
    </p>
</div>

<ng-container *ngIf="debugMode">
    <hr>
    <h3>Debug data</h3>
    <p>Industry data</p>
    <pre>{{ industryJobs | json }}</pre>
    <hr>
    <p>Blueprint data</p>
    <pre>{{ blueprints | json }}</pre>
</ng-container>
