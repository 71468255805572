<div class="container">
    <h3>What is EVIE?</h3>
    <p>EVIE is an online API interface for the game EVE Online. It uses the game's exposed ESI API to display
        information about characters, the in-game market and the game itself.</p>
    <p>Created by <a href="https://github.com/Ionaru" target="_blank">Ionaru</a>. in-game: <code>Ionaru Otsada</code>.
    </p>
</div>
<hr>
<div class="container text-center">
    <h3>Technologies used</h3>
    <p>Thank you all for making this project possible.</p>
    <div class="tech-grid">
        <div>
            <h4>Frontend</h4>
            <p><a href="https://angular.io/" target="_blank">Angular</a></p>
            <p><a href="https://getbootstrap.com/" target="_blank">Bootstrap</a></p>
            <p><a href="https://bootswatch.com/" target="_blank">Bootswatch</a></p>
            <p><a href="https://fontawesome.com/" target="_blank">FontAwesome</a></p>
            <p><a href="https://rxjs-dev.firebaseapp.com/" target="_blank">RxJS</a></p>
        </div>
        <div>
            <h4>Backend</h4>
            <p><a href="https://github.com/axios/axios/" target="_blank">Axios</a></p>
            <p><a href="https://expressjs.com/" target="_blank">Express</a></p>
            <p><a href="https://www.mysql.com/" target="_blank">MySQL</a></p>
            <p><a href="https://nodejs.org/en/" target="_blank">Node.js</a></p>
            <p><a href="https://typeorm.io/" target="_blank">TypeORM</a></p>
        </div>
    </div>
    <div>
        <h4>Special thanks to</h4>
        <p><a href="https://www.docker.com/" target="_blank">Docker</a></p>
        <p><a href="https://eslint.org/" target="_blank">ESLint</a></p>
        <p><a href="https://git-scm.com/" target="_blank">Git</a></p>
        <p><a href="https://github.com/" target="_blank">GitHub</a></p>
        <p><a href="https://www.jetbrains.com/idea/" target="_blank">IntelliJ IDEA</a></p>
        <p><a href="https://www.npmjs.com/" target="_blank">npm</a></p>
        <p><a href="https://sentry.io/" target="_blank">Sentry</a></p>
        <p><a href="https://socket.io/" target="_blank">Socket.IO</a></p>
        <p><a href="https://www.jetbrains.com/teamcity/" target="_blank">TeamCity</a></p>
        <p><a href="https://palantir.github.io/tslint/" target="_blank">TSLint</a></p>
        <p><a href="https://www.typescriptlang.org/" target="_blank">TypeScript</a></p>
    </div>
</div>
<hr>
<div class="container text-center">
    <p>Source code available on <a href="https://github.com/Ionaru/EVIE"
                                   target="_blank">https://github.com/Ionaru/EVIE</a></p>
</div>
