<div class="modal-header">
    <h4 class="modal-title">Logout</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </button>
</div>
<div class="modal-body">
    Are you sure you want to log out?
    <hr>
    <div class="login-container">
        <button (click)="logout()" class="btn btn-primary">Yes, see ya!</button>
    </div>
</div>
