<nav class="navbar fixed-top navbar-expand-lg">
    <a class="navbar-brand" routerLink="/">EVIE</a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        <fa-icon [fixedWidth]="true" [icon]="faChevronDown"></fa-icon>
    </button>

    <div class="collapse navbar-collapse" id="collapseExample" [ngbCollapse]="!isCollapsed">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link" routerLink="/" routerLinkActive="active"
                   [routerLinkActiveOptions]="{ exact: true }">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="homeIcon"></fa-icon></span>
                </a>
            </li>
            <li *ngIf="isLoggedIn" class="nav-item">
                <a class="nav-link" routerLink="dashboard" routerLinkActive="active">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="dashboardIcon"></fa-icon></span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="ore" routerLinkActive="active"
                   [routerLinkActiveOptions]="{ exact: true }">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="orePricesIcon"></fa-icon></span>
                    Ores
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="reprocessing" routerLinkActive="active">
                    <span class="nav-link-text">
                        <fa-icon [fixedWidth]="true" [icon]="reprocessingIcon"></fa-icon>
                    </span>
                    Reprocessing
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="prices" routerLinkActive="active">
                    <span class="nav-link-text">
                        <fa-layers [fixedWidth]="true">
                            <fa-icon [icon]="iskIconBase"></fa-icon>
                            <fa-layers-text content="ISK" style="color: black; font-weight: bold"
                                            transform="shrink-8"></fa-layers-text>
                        </fa-layers>
                    </span>
                    Resource prices
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="production-calculator" routerLinkActive="active">
                    <span class="nav-link-text">
                        <fa-icon [fixedWidth]="true" [icon]="productionCalculatorIcon"></fa-icon>
                    </span>
                    Production Calculator
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="refining-profit" routerLinkActive="active" *ngIf="devEnvironment">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="mineralsIcon"></fa-icon></span>
                    Minerals
                </a>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item d-none d-lg-block disabled">
                <a class="nav-link">
                    <span class="nav-link-text" [ngClass]="{'d-none': !serverOnline}">
                        <span id="eve-players"></span> players online
                    </span>
                    <span class="nav-link-text" [ngClass]="{'d-none': serverOnline}">
                        <fa-icon [fixedWidth]="true" [icon]="connectionIcon" class="text-danger"></fa-icon>
                        API server is offline
                    </span>
                </a>
            </li>
            <li *ngIf="isLoggedIn && (isAdmin || devEnvironment)" class="nav-item">
                <a class="nav-link" routerLink="users" routerLinkActive="active">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="userAdminIcon"></fa-icon></span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="about" routerLinkActive="active">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="aboutIcon"></fa-icon></span>
                </a>
            </li>
            <li *ngIf="isLoggedIn" class="nav-item">
                <a class="nav-link" (click)="logout()">
                    <span class="nav-link-text"><fa-icon [fixedWidth]="true" [icon]="logoutIcon"></fa-icon></span>
                </a>
            </li>
        </ul>
    </div>
</nav>

<nav class="navbar fixed-left navbar-inverse">
    <div class="scrollContainer">
        <a class="character-item" [ngClass]="{'disabled': !hasCharacters}" (click)="nextCharacter()">
            <img src="https://images.evetech.net/characters/{{ char }}/portrait?size=64" alt="Character image">
        </a>
        <a routerLink="dashboard" routerLinkActive="active" class="navbar-item d-lg-none"
           [ngClass]="{'disabled': !activatedCharacter}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/recruitment.svg"
                 alt="Dashboard sheet icon">
        </a>
        <a routerLink="charactersheet" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': true}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/charactersheet.svg"
                 alt="Character sheet icon">
        </a>
        <a routerLink="skills" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': !activatedCharacter}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/skills.svg" alt="Skills icon">
        </a>
        <a routerLink="contacts" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': true}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/contacts.svg" alt="Contacts icon">
        </a>
        <a routerLink="mail" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': true }">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/evemail.svg" alt="Mail icon">
        </a>
        <a routerLink="market" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': !activatedCharacter || !devEnvironment}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/market.svg" alt="Market icon">
        </a>
        <a routerLink="wallet" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': !activatedCharacter}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/wallet.svg" alt="Wallet icon">
        </a>
        <a routerLink="assets" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': !activatedCharacter || !devEnvironment}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/assets.svg" alt="Assets icon">
        </a>
        <a routerLink="planets" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': true}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/planets.svg" alt="Planets icon">
        </a>
        <a routerLink="industry" routerLinkActive="active" class="navbar-item"
           [ngClass]="{'disabled': !activatedCharacter}">
            <img src="https://data.saturnserver.org/eve/Icons/UI/WindowIcons/industry.svg" alt="Industry icon">
        </a>
        <a class="navbar-item time" style="bottom: 30px"
           [ngClass]="{'disabled': true}">
            <fa-icon *ngIf="requestsActive" [fixedWidth]="true" [icon]="requestsActiveIcon"></fa-icon>
            <fa-icon *ngIf="!requestsActive" [fixedWidth]="true" [icon]="requestsInactiveIcon"></fa-icon>
        </a>
        <a class="navbar-item time"
           [ngClass]="{'disabled': true}">
            <span id="EVETime_Hours">{{ hours }}</span>:<span id="EVETime_Minutes">{{ minutes }}</span>
        </a>
    </div>
</nav>
