<div class="container fluid character-dashboard">
    <p class="text-center">
        Welcome to the Character Dashboard, here you can see and manage the characters you added to EVIE, or add
        new characters.
    </p>
    <div class="text-center add-sort-buttons">
        <button class="btn btn-outline-primary add-character-button" (click)="authCharacter()">
            <fa-icon [icon]="faUserPlus"></fa-icon>
            Add a new character
        </button>
        <div ngbDropdown class="d-inline-block btn">
            <button class="btn btn-outline-primary" id="sortChoice" ngbDropdownToggle>Sort by {{ selectedSortOption }}</button>
            <div ngbDropdownMenu aria-labelledby="sortChoice">
                <button ngbDropdownItem *ngFor="let sort of sortOptions" (click)="setSortOption(sort)">{{ sort | titlecase }}</button>
                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="invertSort()">
                    <span *ngIf="!sortInverted">Invert</span>
                    <span *ngIf="sortInverted">Restore</span>
                    sort order
                </button>
            </div>
        </div>
        <div class="d-inline-block btn character-search">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input type="text" class="form-control" placeholder="Character search"
                   [(ngModel)]="characterSearch" [ngbTypeahead]="characterNames" (ngModelChange)="filterCharacters()"
            />
        </div>
    </div>

    <div class="row justify-content-around">
        <div *ngFor="let character of charactersFiltered" [ngClass]="{'selected' : isCharacterSelected(character)}"
             class="character-container2">
            <div class="character-portrait">
                <img src="https://images.evetech.net/characters/{{ character.characterId }}/portrait?size=256" alt="Character image">
            </div>
            <div class="character-info">
                <p>
                    <strong>{{ character.name }}</strong>
                </p>
                <p *ngIf="!character.hasValidAuth" class="text-danger">
                    <strong>Invalid API authorization</strong>
                    <br>
                    Please use the
                    <fa-icon class="text-info" [icon]="faSync"></fa-icon>
                    icon to refresh the authorization for this character.
                </p>
                <p *ngIf="hasShipTypeScope(character)">
                    <ng-container *ngIf="character.currentShip.name && character.currentShip.type;else loading_message">
                        {{ character.currentShip.name }} ({{ character.currentShip.type }})
                    </ng-container>
                </p>
                <a class="character-link" (click)="goToCharacterWalletPage(character).then()" *ngIf="hasWalletScope(character)">
                    <p>
                        <ng-container *ngIf="character.balance;else loading_message">
                            {{ character.balance || 0 | number:'0.2-2' }} ISK
                        </ng-container>
                    </p>
                </a>
                <a class="character-link" (click)="goToCharacterSkillPage(character).then()" *ngIf="hasSkillQueueScope(character)">
                    <p>
                        <ng-template #noSkillInTraining>
                            <span class="text-warning">No skill in training</span>
                        </ng-template>
                        <ng-container *ngIf="character.currentTrainingSkill; else noSkillInTraining">

                            {{ character.currentTrainingSkill.name }}
                            <span class="text-info">
                                {{ romanize(character.currentTrainingSkill?.finished_level) }}
                            </span>
                            <br>

                            <span *ngIf="character.totalTrainingCountdown" [ngClass]="{'text-warning': skillQueueLow(character)}">
                                {{ character.currentTrainingCountdown }}
                                <br>
                                ( {{ character.totalTrainingCountdown }} )
                            </span>

                        </ng-container>
                    </p>
                </a>
                <p class="button-row">
                    <button class="btn" [disabled]="isCharacterSelected(character)"
                            [ngClass]="getActivateButtonClass(character)" (click)="switchToCharacter(character).then()">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                    <button class="btn btn-outline-info" (click)="authCharacter(character)">
                        <fa-icon [icon]="faSync"></fa-icon>
                    </button>
                    <button class="btn btn-outline-danger" (click)="deleteCharacter(character).then()">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>

<ng-template #loading_message>
    <app-loading-message [small]="true" class="d-flex justify-content-center">Loading...</app-loading-message>
</ng-template>
