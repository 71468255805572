<app-no-scopes-message *ngIf="missingAllRequiredScopes">
    To display the industry system overview page, you need to grant the "Read structures" scopes.
</app-no-scopes-message>

<ng-container *ngIf="!missingAllRequiredScopes">
    <div *ngFor="let character of allCharacters" class="d-flex justify-content-between character-adder">
        <div class="character-adder-name">
            <img src="https://images.evetech.net/characters/{{ character.characterId }}/portrait?size=32"
                 alt="{{ character.name }}-avatar">
            &nbsp;
            <strong>{{ character.name }}</strong>
        </div>
        <div class="character-adder-buttons">
            <strong *ngIf="overview && characterJobs[character.characterId] && selectedCharacters.includes(character)">
                <ng-container *ngIf="!characterJobs[character.characterId].totalJobs">
                    No industry jobs found
                </ng-container>

                <ng-container *ngIf="characterJobs[character.characterId].inProgressJobs">
                    {{characterJobs[character.characterId].inProgressJobs}} in progress
                </ng-container>
                <ng-container *ngIf="characterJobs[character.characterId].inProgressJobs && characterJobs[character.characterId].readyJobs">
                    /
                </ng-container>
                <ng-container *ngIf="characterJobs[character.characterId].readyJobs">
                    {{characterJobs[character.characterId].readyJobs}} ready for delivery
                </ng-container>
            </strong>
            <strong *ngIf="!hasRequiredScopes(character)" class="text-danger">
                This character does not have the required scopes to be added
            </strong>
            &nbsp;
            <button *ngIf="!selectedCharacters.includes(character)" class="btn btn-outline-primary btn-sm"
                    [disabled]="!hasRequiredScopes(character)"
                    (click)="addCharacterToOverview(character, icon)">
                <fa-icon #icon [icon]="addIcon" [fixedWidth]="true"></fa-icon>
            </button>
            <button *ngIf="selectedCharacters.includes(character)" class="btn btn-outline-danger btn-sm"
                    (click)="removeCharacterFromOverview(character)">
                <fa-icon [icon]="removeIcon" [fixedWidth]="true"></fa-icon>
            </button>
        </div>
    </div>
</ng-container>

<hr>

<div class="system-overview" *ngIf="!missingAllRequiredScopes">
    <ngb-accordion *ngIf="overview && overview.length">
        <ngb-panel *ngFor="let system of overview">
            <ng-template ngbPanelTitle>
                <div class="d-flex justify-content-between">
                    <span>
                        <fa-icon [icon]="openAccordionIcon"></fa-icon>
                        <strong> {{ system.name }}</strong>
                        <small class="sec-{{ system.securityClass }}">
                            {{ system.securityStatus | number:'1.1-1' }}
                        </small>
                    </span>
                    <strong>
                        <ng-container *ngIf="system.inProgressJobs">
                            {{system.inProgressJobs}} in progress
                        </ng-container>
                        <ng-container *ngIf="system.inProgressJobs && system.readyJobs">
                            /
                        </ng-container>
                        <ng-container *ngIf="system.readyJobs">
                            {{system.readyJobs}} ready for delivery
                        </ng-container>
                    </strong>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <ng-container *ngFor="let location of system.locations">
                    <h5>{{ location.name  }} ( {{ location.type }} )</h5>
                    <table class="job-info">
                        <!-- TODO: Highlight finished jobs -->
                        <tr *ngFor="let job of location.jobs">
                            <td class="text-center" *ngIf="selectedCharacters > 1">
                                ( <fa-icon [icon]="installerIcon"></fa-icon> {{ job.installerName }} )
                            </td>

                            <td class="text-center">
                                <img src="https://images.evetech.net/types/{{ job.blueprint_type_id }}/{{job.inputImageType}}?size=32" alt="bp">
                            </td>
                            <td class="text-center"><fa-icon [fixedWidth]="true" [icon]="arrowRight"></fa-icon></td>
                            <td class="text-center"><fa-icon [fixedWidth]="true" [icon]="job.activityIcon"></fa-icon></td>

                            <td class="text-center">
                                <ng-container *ngIf="![IndustryActivity.research_material_efficiency, IndustryActivity.research_time_efficiency].includes(job.activity_id)">
                                    <fa-icon [icon]="jobRunsIcon"></fa-icon> {{job.runs}}
                                </ng-container>

                                <ng-container [ngSwitch]="job.activity_id">
                                    <ng-container *ngSwitchCase="IndustryActivity.research_material_efficiency">+{{ job.runs }}%</ng-container>
                                    <ng-container *ngSwitchCase="IndustryActivity.research_time_efficiency">+{{ job.runs * 2 }}%</ng-container>
                                    <ng-container *ngSwitchCase="IndustryActivity.invention">( ~{{ job.probability * 100 | number:'1.0-0' }}% )</ng-container>
                                </ng-container>
                            </td>

                            <td class="text-center"><fa-icon [fixedWidth]="true" [icon]="arrowRight"></fa-icon></td>

                            <td class="text-center">
                                <img src="https://images.evetech.net/types/{{ job.product_type_id }}/{{job.outputImageType}}?size=32" alt="bp">
                            </td>

                            <td>{{job.productName}}</td>
                            <td class="job-time">
                                <ng-container *ngIf="job.timeCountdown.value > 0">
                                    {{job.timeCountdown}} -
                                </ng-container>
                                {{job.percentageDone}} %
                            </td>
                        </tr>
                    </table>
                </ng-container>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <h4 *ngIf="overview && !overview.length">No industry jobs found</h4>
</div>

<ng-container *ngIf="debugMode">
    <hr>
    <h3>Debug data</h3>
    <p>Industry data</p>
    <pre>{{ overview | json }}</pre>
</ng-container>
