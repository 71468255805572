<app-no-scopes-message *ngIf="missingAllRequiredScopes">
    To display the industry page, you need to grant the "Read industry jobs" and "Read blueprints" scopes.
</app-no-scopes-message>

<div class="container industry-page-container" *ngIf="missingAllRequiredScopes === false">

    <div class="text-center">
        <div class="btn-group" role="group">
            <button routerLink="jobs" routerLinkActive="active" type="button" class="btn btn-outline-primary">
                Industry jobs
            </button>
            <button routerLink="system-overview" routerLinkActive="active" type="button" class="btn btn-outline-primary">
                System overview
            </button>
        </div>
    </div>

    <hr>

    <router-outlet></router-outlet>
</div>
