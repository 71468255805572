<div class="container">

    <h4>Input</h4>

    <form>
        <div class="form-group four-wide">
            <div class="form-part">
                <label for="type">Structure type</label>
                <select class="form-control" name="type" id="type"  [(ngModel)]="structureType">
                    <option [ngValue]="'citadel'">Citadel</option>
                    <option [ngValue]="'athanor'">Athanor</option>
                    <option [ngValue]="'tatara'">Tatara</option>
                </select>
            </div>

            <div class="form-part">
                <label for="rigs">Structure rigs</label>
                <select class="form-control" name="rigs" id="rigs" [(ngModel)]="structureRigs">
                    <option [ngValue]="'t0'">No rigs</option>
                    <option [ngValue]="'t1'">T1 rigs</option>
                    <option [ngValue]="'t2'">T2 rigs</option>
                </select>
            </div>

            <div class="form-part">
                <label for="location">Structure location</label>
                <select class="form-control" name="location" id="location" [(ngModel)]="structureLocation">
                    <option [ngValue]="'highsec'">Highsec</option>
                    <option [ngValue]="'lowsec'">Lowsec</option>
                    <option [ngValue]="'nullsec'">Nullsec / W-Space</option>
                </select>
            </div>

            <div class="form-part">
                <label for="tax">Tax</label>
                <div class="input-group mb-2 mr-sm-2">
                    <input name="tax" type="number" class="form-control" id="tax" [(ngModel)]="tax" min="0">
                    <div class="input-group-append">
                        <div class="input-group-text">%</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group five-wide">

            <div class="form-part">
                <label for="reprocessingSkill">Apply character skills</label>
                <div class="btn-group btn-group-toggle w-100">
                    <label class="btn-outline-primary no-text-overflow" ngbButtonLabel *ngIf="isLoggedIn() && hasScopes()">
                        <input type="checkbox" ngbButton [(ngModel)]="useCharacterSkills" name="useCharacterSkills">
                        <fa-icon [icon]="useCharacterSkills ? characterIcon : characterDisabledIcon" [fixedWidth]="true"></fa-icon>
                        {{getCharacterName()}}
                    </label>
                    <label class="btn-outline-primary no-text-overflow" ngbButtonLabel *ngIf="!isLoggedIn()" disabled>
                        <input type="checkbox" ngbButton [(ngModel)]="useCharacterSkills" name="useCharacterSkills" disabled>
                        <fa-icon [icon]="notLoggedInIcon" [fixedWidth]="true"></fa-icon>
                        Not logged in
                    </label>
                    <label class="btn-outline-primary no-text-overflow" ngbButtonLabel *ngIf="isLoggedIn() && !hasScopes()" disabled>
                        <input type="checkbox" ngbButton [(ngModel)]="useCharacterSkills" name="useCharacterSkills" disabled>
                        <fa-icon [icon]="missingScopeIcon" [fixedWidth]="true"></fa-icon>
                        Missing skills scope
                    </label>
                </div>
            </div>

            <div class="form-part">
                <label for="reprocessingSkill">Reprocessing skill level</label>
                <input name="reprocessingSkill" type="number" class="form-control" id="reprocessingSkill" max="5" min="0"
                       [(ngModel)]="skillValues.reprocessing" [disabled]="useCharacterSkills">
            </div>
            <div class="form-part">
                <label for="reprocessingEfficiencySkill">Reprocessing Efficiency skill level</label>
                <input name="reprocessingEfficiencySkill" type="number" class="form-control" max="5" min="0"
                       [disabled]="useCharacterSkills"
                       id="reprocessingEfficiencySkill" [(ngModel)]="skillValues.reprocessingEfficiency">
            </div>
            <div class="form-part">
                <label for="oreProcessingSkill">Avg. Processing skill level</label>
                <input name="oreProcessingSkill" type="number" class="form-control" id="oreProcessingSkill" max="5" min="0"
                       [(ngModel)]="skillValues.averageOreProcessing" [disabled]="useCharacterSkills">
            </div>
            <div class="form-part">
                <label for="implantValue">Implant bonus</label>
                <select class="form-control" name="implant" id="implantValue" [(ngModel)]="implantLevel">
                    <option [ngValue]="0">0%</option>
                    <option [ngValue]="1">1%</option>
                    <option [ngValue]="2">2%</option>
                    <option [ngValue]="4">4%</option>
                </select>
            </div>
        </div>

        <div class="form-group">
            <label for="oreText">Paste ores, ice or items here, compatible with inventory and assets page.</label>
            <textarea name="oreText" class="form-control" id="oreText" rows="10" [(ngModel)]="oreText"></textarea>
        </div>

        <button [disabled]="buttonDisabled" class="btn btn-primary" (click)="run()">Calculate</button>

    </form>

    <hr>

    <app-loading-message *ngIf="buttonDisabled" class="text-center">
        Running calculation...
    </app-loading-message>

    <div *ngIf="isNotEmpty()">
        <h4>Results ( {{efficiency | number: '1.0-2'}}% )</h4>
        <div *ngFor="let item of refiningData | keyvalue">
            <img src="https://images.evetech.net/types/{{item.key}}/icon?size=32" alt="{{getName(item.key)}}">
            {{getName(item.key)}} {{item.value | number: '1.0-0'}}
        </div>
    </div>

</div>
