<div class="index-container container">
    <div class="info-container">
        <h1>Welcome to EVIE!</h1>
        <br>
        <p>
            EVIE can be best described as an <strong>EVE Online API viewer</strong>, it enables you to view your
            EVE Online character information without having to log into the game.
        </p>
        <br>
        <p>
            My goal is to provide you with a simple-to-use API viewer that gives you as much useful information about
            your characters as possible.
        </p>
        <br>
        <p>
            This website uses SSO login from EVE Online.
            This will also enable you to save multiple characters and quickly access their data.
            <br>
            The source code of this website is publicly available on
            <a class="underline" href="https://github.com/Ionaru/EVIE" target="_blank" rel="noopener">GitHub</a>,
            so feel free to nose around and discover how it all works.
        </p>
        <br>
        <p>Currently functional pages:</p>
        <ul>
            <li><a class="underline" routerLink="ore">Ore reprocessing table</a></li>
            <li><a class="underline" routerLink="prices/belt">Ore prices table</a></li>
            <li><a class="underline" routerLink="prices/fullerenes">Gas prices table</a></li>
            <li><a class="underline" routerLink="reprocessing">Reprocessing calculator</a></li>
            <li>Character dashboard</li>
            <li>Skills page</li>
            <li>Wallet balance & journal</li>
            <li>Industry jobs</li>
        </ul>
    </div>

    <div class="center" *ngIf="!isLoggedIn">
        <hr>
        <br>
        <div class="d-flex button-container">
            <button class="btn btn-primary" (click)="authCharacter()">Login with SSO</button>
        </div>
    </div>
</div>
