<table class="table table-sm">
    <thead>
    <tr>
        <th [ngClass]="{sortable: header.sort}" *ngFor="let header of columns" [class]="header.titleClass"
            (click)="header.sort ? sort(header) : null">
            <ng-container *ngIf="currentSort && currentSort === header">
                <span *ngIf="!invert"><fa-icon [icon]="sortAscendingIcon"></fa-icon></span>
                <span *ngIf="invert"><fa-icon [icon]="sortDescendingIcon"></fa-icon></span>
            </ng-container>
            <ng-container *ngIf="!(currentSort && currentSort === header) && header.sort">
                <span><fa-icon [icon]="noSortIcon"></fa-icon></span>
            </ng-container>
            &nbsp;
            <span [innerHTML]="header.title ? header.title : capitalize(header.attribute)"></span>
            &nbsp;
            <span class="hint-button" *ngIf="header.hint" placement="right" [ngbTooltip]="header.hint">
                <fa-icon [icon]="hintIcon"></fa-icon>
            </span>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of data">
        <td *ngFor="let column of columns" [ngClass]="[column.attribute, getClass(column, row)]" class="align-middle">
            <span [innerHTML]="prefixFunction(column, row) || column.prefix"></span>
            <ng-container [ngSwitch]="column.pipe">
                <ng-container *ngSwitchCase="'number'">
                    {{ getData(column, row) | number:column.pipeVar ? column.pipeVar : '1.0-0' }}
                </ng-container>
                <ng-container *ngSwitchCase="'date'">
                    {{ getData(column, row) | date:column.pipeVar ? column.pipeVar : 'yyyy-MM-dd HH:mm:ss' }}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{ getData(column, row) }}
                </ng-container>
            </ng-container>
            <span [innerHTML]="suffixFunction(column, row) || column.suffix"></span>
        </td>
    </tr>
    </tbody>
</table>
