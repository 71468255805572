<div class="d-flex justify-content-center">
    <div class="text-center">
        <div class="btn-group" role="group">
            <button routerLink="/refining-profit/belt" routerLinkActive="active" type="button" class="btn btn-outline-primary">
                Belt
            </button>
            <button routerLink="/refining-profit/moon" routerLinkActive="active" type="button" class="btn btn-outline-primary">
                Moon
            </button>
            <button routerLink="/refining-profit/triglavian" routerLinkActive="active" type="button" class="btn btn-outline-primary">
                Triglavian
            </button>
        </div>
    </div>
</div>

<hr>

<label>
    Refining yield
    <input type="number" [(ngModel)]="refiningYield">
</label>
<button (click)="ngOnInit()">Run calculation</button>
<hr>
<ng-container *ngIf="visibleData; else ore_loading_message">
    <p>Prices are based on 8.000m³ of ore and buying in Domain and selling in Amarr</p>
    <app-sor-table [data]="visibleData" [columns]="tableSettings"></app-sor-table>
</ng-container>

<ng-template #ore_loading_message>
    <app-loading-message>Calculating profit...</app-loading-message>
</ng-template>
